import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addPyqsAsync,
  getPyqsByIdAsync,
  updatePyqsByIdAsync,
  getAllPyqsAsync,
  deletePyqsAsync,
} from "./freeresource.async";

const initialState = {
  createPyqsLoader: false,
  createPyqs: [],
  getByIdPyqsLoader: false,
  getByIdPyqs: [],
  updatePyqsLoader: false,
  updatePyqs: [],
  allPyqsLoader: false,
  allPyqs: [],
  deletePyqsLoader: false,
  deletePyqs: [],
};

export const freeresourceSlice = createSlice({
  name: "freeresource",
  initialState,

  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(addPyqsAsync.pending), (state) => {
      state.createPyqsLoader = true;
    });
    builder.addMatcher(isAnyOf(addPyqsAsync.fulfilled), (state, action) => {
      state.createPyqsLoader = false;
      state.createPyqs = action.payload;
    });
    builder.addMatcher(isAnyOf(addPyqsAsync.rejected), (state, action) => {
      state.createPyqsLoader = false;
    });

    builder.addMatcher(isAnyOf(getPyqsByIdAsync.pending), (state) => {
      state.getByIdPyqsLoader = true;
    });
    builder.addMatcher(isAnyOf(getPyqsByIdAsync.fulfilled), (state, action) => {
      state.getByIdPyqsLoader = false;
      state.getByIdPyqs = action.payload;
    });
    builder.addMatcher(isAnyOf(getPyqsByIdAsync.rejected), (state, action) => {
      state.getByIdPyqsLoader = false;
    });

    builder.addMatcher(isAnyOf(updatePyqsByIdAsync.pending), (state) => {
      state.updatePyqsLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updatePyqsByIdAsync.fulfilled),
      (state, action) => {
        state.updatePyqsLoader = false;
        state.updatePyqs = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updatePyqsByIdAsync.rejected),
      (state, action) => {
        state.updatePyqsLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(getAllPyqsAsync.pending), (state) => {
      state.allPyqsLoader = true;
    });
    builder.addMatcher(isAnyOf(getAllPyqsAsync.fulfilled), (state, action) => {
      state.allPyqsLoader = false;
      state.allPyqs = action.payload;
    });
    builder.addMatcher(isAnyOf(getAllPyqsAsync.rejected), (state, action) => {
      state.allPyqsLoader = false;
    });

    builder.addMatcher(isAnyOf(deletePyqsAsync.pending), (state) => {
      state.deletePyqsLoader = true;
    });
    builder.addMatcher(isAnyOf(deletePyqsAsync.fulfilled), (state, action) => {
      state.deletePyqsLoader = false;
      state.deletePyqs = action.payload;
    });
    builder.addMatcher(isAnyOf(deletePyqsAsync.rejected), (state, action) => {
      state.deletePyqsLoader = false;
    });
  },

  reducers: {
    emptyfreeresourceSlice: () => initialState,
  },
});

export const { emptyfreeresourceSlice } = freeresourceSlice.actions;

export default freeresourceSlice.reducer;
